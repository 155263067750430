import { cx } from '@emotion/css';

import { format } from 'date-fns';

import { useQuery } from '@tanstack/react-query';

import { ButtonDownloadAgreementPurchase } from 'features/download-agreement-purchase/ui';
import { ButtonDownloadDebtCert } from 'features/download-debt-cert/ui';
import { ButtonDownloadPersonalData } from 'features/download-personal-data/ui';

import { OrderApi } from 'entities/order';
import { getCurrentStage } from 'entities/payment/lib';

import { Spinner } from 'shared/ui';

export const OrderInfo = ({ guid }: { guid: string }) => {
  const { data: order } = useQuery({
    queryKey: [OrderApi.KEY, 'getOrder', guid],
    queryFn: () => OrderApi.getOrder(guid),
  });

  if (!order) return <Spinner />;

  const stage = getCurrentStage(order.paymentPlan, order.payments);

  return (
    <div className="flex flex-col justify-center gap-4">
      <div className="grid grid-cols-2 gap-10">
        <div>
          <p className="text-[18px] font-bold">Условия рассрочки</p>
          <div className="font-second mt-4 flex flex-col gap-2">
            <div className="grid h-8 grid-cols-[1fr_1fr] items-center border-b">
              <span className="text-sm leading-[120%]">Номер договора:</span>
              <span className="text-right text-sm font-bold">
                {order.number}
              </span>
            </div>
            <div className="grid h-8 grid-cols-[1fr_1fr] items-center border-b">
              <span className="text-sm leading-[120%]">Дата оформления:</span>
              <span className="text-right text-[16px] font-bold">
                {format(new Date(order.date), 'dd.MM.yyyy')}
              </span>
            </div>
            <div className="grid h-8 grid-cols-[1fr_1fr] items-center border-b">
              <span className="text-sm leading-[120%]">Сумма заказа:</span>
              <span className="text-right text-[16px] font-bold">
                {order.sum} ₽
              </span>
            </div>
            <div className="grid h-8 grid-cols-[1fr_1fr] items-center border-b">
              <span className="text-sm leading-[120%]">Сумма в рассрочку:</span>
              <span className="text-right text-[16px] font-bold">
                {order.sum} ₽
              </span>
            </div>
            {order.payments.length && (
              <div className="grid h-8 grid-cols-[1fr_1fr] items-center border-b">
                <span className="text-sm leading-[120%]">
                  Первоначальный платеж:
                </span>
                <span className="text-right text-[16px] font-bold">
                  {order.payments[0].sum} ₽
                </span>
              </div>
            )}
            <ButtonDownloadAgreementPurchase orderGuid={order.guid} />
            <ButtonDownloadDebtCert orderGuid={order.guid} />
            <ButtonDownloadPersonalData orderGuid={order.guid} />
          </div>
        </div>
        <div>
          <p className="text-[18px] font-bold">Информация по заказу</p>
          <div className="font-second mt-4 flex flex-col gap-2">
            {order.cart.cart_items.map((cardItem) => (
              <div
                key={cardItem.item.article}
                className="grid h-8 grid-cols-[1fr_1fr] items-center border-b"
              >
                <span className="text-sm leading-[120%]">
                  {cardItem.item.name}
                </span>
                <span className="text-right text-[16px] font-bold">
                  {cardItem.item.price} ₽
                </span>
              </div>
            ))}
            <div className="grid h-8 grid-cols-[1fr_1fr] items-center ">
              <span className="text-sm leading-[120%]"></span>
              <span className="text-right text-[16px] font-bold">
                {order.cart.cart_sum} ₽
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-10">
        <div className="w-full">
          <p className="text-[18px] font-bold">График платежей</p>
          <div className="font-second relative mt-4 flex flex-col gap-2">
            {!!order &&
              !!stage &&
              order.paymentPlan.map((paymentPlan) => (
                <div
                  className={cx(
                    'grid h-8 grid-cols-[1fr_1fr_1fr] items-center border-b',
                    stage.stage! > paymentPlan.stage && 'opacity-20',
                  )}
                >
                  <span className="text-sm leading-[120%]">
                    {format(new Date(paymentPlan.date), 'dd.MM.yyyy')}
                  </span>
                  <span className="text-right text-sm">Сумма платежа</span>
                  <span className="text-right text-[16px] font-bold">
                    {stage.stage === paymentPlan.stage && stage.isPartial
                      ? paymentPlan.sum - stage.paid
                      : paymentPlan.sum}
                    ₽
                  </span>
                </div>
              ))}
          </div>
        </div>
        <div className="w-full">
          <p className="text-[18px] font-bold">История платежей</p>
          <div className="font-second relative mt-4 flex flex-col gap-2">
            {order.payments.length ? (
              order.payments.map((payment) => (
                <div
                  className={cx(
                    'grid h-8 grid-cols-[1fr_1fr_1fr] items-center border-b',
                  )}
                >
                  <span className="text-sm leading-[120%]">
                    {format(new Date(payment.date), 'dd.MM.yyyy')}
                  </span>
                  <span className="text-right text-sm">Сумма платежа</span>
                  <span className="text-right text-[16px] font-bold">
                    {payment.sum}₽
                  </span>
                </div>
              ))
            ) : (
              <span className="text-sm">нет данных</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
