import type { Status } from '../model';

export const getHue = (status: Status) => {
  let hue = 0;
  switch (status) {
    case 'NEW':
    case 'CREATED':
      hue = 220;
      break;
    case 'EXPIRED':
      hue = 50;
      break;
    case 'PARTIAL_REFUND':
      hue = 50;
      break;
    case 'RETURN':
    case 'CANCELED':
      hue = 0;
      break;
    case 'PAYED':
    case 'PAID':
      hue = 120;
      break;
    case 'ISSUED':
      hue = 280;
      break;
  }

  return hue;
};

export const StatusTag = ({ status = 'NEW' }: { status: Status }) => {
  let hue = 0,
    text = '';

  switch (status) {
    case 'NEW':
    case 'CREATED':
      hue = 220;
      text = 'Новый';
      break;
    case 'EXPIRED':
      hue = 50;
      text = 'Просрочен';
      break;
    case 'PARTIAL_REFUND':
      hue = 50;
      text = 'Частичный возврат';
      break;
    case 'RETURN':
    case 'CANCELED':
      hue = 0;
      text = 'Возврат';
      break;
    case 'PAYED':
    case 'PAID':
      hue = 120;
      text = 'Оплачен';
      break;
    case 'ISSUED':
      hue = 280;
      text = 'Выдан';
      break;
  }

  return (
    <span
      className="rounded-[2px] border px-2 py-1 text-xs font-semibold text-white"
      style={{
        borderColor: `hsl(${hue}, 50%, 40%)`,
        backgroundColor: `hsl(${hue}, 50%, 40%)`,
      }}
    >
      {text}
    </span>
  );
};
