import { useMutation } from '@tanstack/react-query';

import { DocumentApi } from 'entities/document';

import { onAxiosError } from 'shared/lib';

export const ButtonDownloadDebtCert = ({
  orderGuid,
}: {
  orderGuid: string;
}) => {
  const onDownloadPersonalDataMutation = useMutation({
    mutationFn: () => DocumentApi.debtCertificate(orderGuid),
    onSuccess: (blob) => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'debt-certificate.pdf';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);
    },
    onError: onAxiosError,
  });

  return (
    <span>
      <a
        href="/"
        className="cursor-pointer underline hover:text-brand-main-hover"
        onClick={(event) => {
          event.preventDefault();
          onDownloadPersonalDataMutation.mutate();
        }}
      >
        Справка о задолженности и погашении
      </a>
    </span>
  );
};
