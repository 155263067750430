import { cx } from '@emotion/css';

import { format } from 'date-fns';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { toast } from 'react-toastify';

import { ButtonDownloadAgreementPurchase } from 'features/download-agreement-purchase/ui';
import { ButtonDownloadDebtCert } from 'features/download-debt-cert/ui';
import { ButtonDownloadPersonalData } from 'features/download-personal-data/ui';
import { ConfirmDialog, PayDialog } from 'features/pay/ui';

import { OrderApi } from 'entities/order';
import { PaymentApi } from 'entities/payment';
import { getCurrentStage } from 'entities/payment/lib';

import { onAxiosError } from 'shared/lib';
import { Button, ButtonColored, Spinner } from 'shared/ui';

export const InstallmentInfo = () => {
  const client = useQueryClient();

  const [isOpen, setIsOpen] = useState(false);
  const [paymentSum, setPaymentSum] = useState(0);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);

  const { data } = useQuery({
    queryKey: [OrderApi.KEY, 'getOrders'],
    queryFn: OrderApi.getOrders,
  });

  const { data: order } = useQuery({
    queryKey: [OrderApi.KEY, 'getOrder'],
    queryFn: () => OrderApi.getOrder(data![0].guid),
    enabled: !!data,
  });

  const paymentMutation = useMutation({
    mutationFn: () =>
      PaymentApi.chargeSequentially({
        orderGuid: data![0].guid,
        sum: paymentSum,
      }),
    onSuccess: () => {
      toast.success('Оплата прошла успешно');
      client.refetchQueries({
        queryKey: [OrderApi.KEY, 'getOrder'],
      });
    },
    onError: onAxiosError,
  });

  if (!order) return <Spinner />;

  const paymentRest =
    +order.sum -
    order.payments.reduce(
      (prev, next) => prev + (!next.success ? +next.sum : 0),
      0,
    );

  const stage = getCurrentStage(order.paymentPlan, order.payments);

  return (
    <div>
      <div className="flex flex-col justify-center gap-10">
        <div className="flex flex-row gap-20">
          <div>
            <p className="text-[18px]">Условия рассрочки</p>
            <div className="font-second mt-4 flex flex-col gap-2">
              <div className="grid h-8 grid-cols-[1fr_1fr] items-center border-b">
                <span className="text-sm leading-[120%]">Номер договора:</span>
                <span className="text-right text-sm font-bold">
                  {order.number}
                </span>
              </div>
              <div className="grid h-8 grid-cols-[1fr_1fr] items-center border-b">
                <span className="text-sm leading-[120%]">Дата оформления:</span>
                <span className="text-right text-[16px] font-bold">
                  {format(new Date(order.date), 'dd.MM.yyyy')}
                </span>
              </div>
              <div className="grid h-8 grid-cols-[1fr_1fr] items-center border-b">
                <span className="text-sm leading-[120%]">Сумма заказа:</span>
                <span className="text-right text-[16px] font-bold">
                  {order.sum} ₽
                </span>
              </div>
              <div className="grid h-8 grid-cols-[1fr_1fr] items-center border-b">
                <span className="text-sm leading-[120%]">
                  Сумма в рассрочку:
                </span>
                <span className="text-right text-[16px] font-bold">
                  {order.sum} ₽
                </span>
              </div>
              {order.payments.length && (
                <div className="grid h-8 grid-cols-[1fr_1fr] items-center border-b">
                  <span className="text-sm leading-[120%]">
                    Первоначальный платеж:
                  </span>
                  <span className="text-right text-[16px] font-bold">
                    {order.payments[0].sum} ₽
                  </span>
                </div>
              )}
              <ButtonDownloadAgreementPurchase orderGuid={order.guid} />
              <ButtonDownloadDebtCert orderGuid={order.guid} />
              <ButtonDownloadPersonalData orderGuid={order.guid} />
            </div>
          </div>
          <div>
            <p className="text-[18px]">Информация по заказу</p>
            <div className="font-second mt-4 flex flex-col gap-2">
              {order.cart.cart_items.map((cardItem) => (
                <div
                  key={cardItem.item.article}
                  className="grid h-8 grid-cols-[1fr_1fr] items-center border-b"
                >
                  <span className="text-sm leading-[120%]">
                    {cardItem.item.name}
                  </span>
                  <span className="text-right text-[16px] font-bold">
                    {cardItem.item.price} ₽
                  </span>
                </div>
              ))}
              <div className="grid h-8 grid-cols-[1fr_1fr] items-center ">
                <span className="text-sm leading-[120%]"></span>
                <span className="text-right text-[16px] font-bold">
                  {order.cart.cart_sum} ₽
                </span>
              </div>
            </div>
          </div>
          <div>
            <p className="text-[18px]">История платежей</p>
            <div className="font-second relative mt-4 flex flex-col gap-2">
              {order.payments.length ? (
                order.payments.map((payment) => (
                  <div
                    className={cx(
                      'grid h-8 grid-cols-[1fr_1fr_1fr] items-center border-b',
                    )}
                  >
                    <span className="text-sm leading-[120%]">
                      {format(new Date(payment.date), 'dd.MM.yyyy')}
                    </span>
                    <span className="text-right text-sm">Сумма платежа</span>
                    <span className="text-right text-[16px] font-bold">
                      {payment.sum}₽
                    </span>
                  </div>
                ))
              ) : (
                <span className="text-sm">нет данных</span>
              )}
            </div>
          </div>
        </div>
        <div className="w-2/5">
          <p className="text-[18px]">График платежей</p>
          <div className="font-second relative mt-4 flex flex-col gap-2">
            {!!order &&
              !!stage &&
              order.paymentPlan.map((paymentPlan) => (
                <div
                  key={Math.random()}
                  className={cx(
                    'grid h-8 grid-cols-[1fr_1fr_1fr] items-center border-b',
                    stage.stage! > paymentPlan.stage && 'opacity-20',
                  )}
                >
                  <span className="text-sm leading-[120%]">
                    {format(new Date(paymentPlan.date), 'dd.MM.yyyy')}
                  </span>
                  <span className="text-right text-sm">Сумма платежа</span>
                  <span className="text-right text-[16px] font-bold">
                    {stage.stage === paymentPlan.stage && stage.isPartial
                      ? paymentPlan.sum - stage.paid
                      : paymentPlan.sum}
                    ₽
                  </span>
                  {stage.stage === paymentPlan.stage && (
                    <Button
                      className="absolute -right-52 h-9"
                      onClick={() => {
                        setPaymentSum(
                          stage.isPartial
                            ? paymentPlan.sum - stage.paid
                            : paymentPlan.sum,
                        );
                        setIsOpenConfirm(true);
                      }}
                    >
                      Оплатить
                    </Button>
                  )}
                </div>
              ))}
            {!!paymentRest && (
              <ButtonColored
                className="mt-4 w-[233px]"
                onClick={() => setIsOpen(true)}
              >
                Оплатить досрочно
              </ButtonColored>
            )}
          </div>
        </div>
      </div>
      <PayDialog isOpen={isOpen} onClose={() => setIsOpen(false)} />
      <ConfirmDialog
        isOpen={isOpenConfirm}
        onClose={() => setIsOpenConfirm(false)}
        onContinue={() => {
          paymentMutation.mutate();
          setIsOpenConfirm(false);
        }}
        title="Уверены, что хотите продолжить?"
        paragraph={`подтверждая действие, с вашей карты будут списаны денежные средства в размере ${paymentSum} ₽`}
      />
    </div>
  );
};
