import type { HTMLAttributes } from 'react';

import { Button, ButtonColored, DialogSimple } from 'shared/ui';

export const ConfirmDialog = ({
  isOpen,
  title,
  paragraph,
  onContinue,
  onClose,
}: {
  isOpen: boolean;
  title: string;
  paragraph: string;
  onContinue: () => void;
  onClose: () => void;
} & HTMLAttributes<HTMLDivElement>) => {
  return (
    <DialogSimple isOpen={isOpen} onClose={onClose}>
      <div className="flex size-full">
        <div className="flex w-full flex-col gap-4 self-center bg-white">
          <p className="flex flex-col text-center text-xl font-normal md:text-2xl">
            <span className="text-[30px]">{title}</span>
          </p>
          <p className="text-center opacity-60">{paragraph}</p>
          <div className="flex justify-center gap-2">
            <ButtonColored className="relative" onClick={onContinue}>
              Подтвердить
            </ButtonColored>
            <Button onClick={onClose}>Отменить</Button>
          </div>
        </div>
      </div>
    </DialogSimple>
  );
};
