export const formatPhoneNumber = (input: string): string => {
  let phoneNumber = input.replace(/\D/g, '');

  if (phoneNumber.startsWith('+7')) {
    phoneNumber = phoneNumber.substring(2);
  }

  if (phoneNumber.startsWith('7') || phoneNumber.startsWith('8')) {
    phoneNumber = phoneNumber.substring(1);
  }

  phoneNumber = phoneNumber.substring(0, 10);

  return `+7 (${phoneNumber.substring(0, 3)}) ${phoneNumber.substring(
    3,
    6,
  )}-${phoneNumber.substring(6, 8)}-${phoneNumber.substring(8, 10)}`;
};
