import { format } from 'date-fns';

import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import { OrderInfo } from 'pages/history-page/ui';

import { OrderApi } from 'entities/order';
import { StatusTag } from 'entities/status';
import { getHue } from 'entities/status/ui';

import { DialogSimple, Spinner } from 'shared/ui';

export const HistoryPage = () => {
  const [activeOrderGuid, setActiveOrderGuid] = useState<string | null>(null);
  const { data } = useQuery({
    queryKey: [OrderApi.KEY, 'getOrders'],
    queryFn: OrderApi.getOrders,
  });

  if (!data) return <Spinner />;

  return (
    <>
      <div className="flex flex-col justify-center gap-4 ">
        <p className="text-[18px]">История рассрочек</p>
        <div className=" grid grid-cols-3 gap-6">
          {data.map((item) => (
            <div
              className="cursor-pointer rounded-l border bg-[#f7f7f9] p-4 transition-transform hover:scale-95"
              onClick={() => setActiveOrderGuid(item.guid)}
              style={{
                borderColor: `hsl(${getHue(item.status)}, 50%, 40%)`,
              }}
            >
              <div className="flex flex-col gap-2">
                <div className="flex gap-2">
                  <div className="w-full">
                    <p className="text-[18px]">Сумма рассрочки</p>
                    <p className="text-[20px]">{item.sum} ₽</p>
                  </div>
                  <div className="flex h-fit justify-end">
                    <StatusTag status={item.status} />
                  </div>
                </div>
                <div>
                  <p className="text-sm text-[#8a919c]">
                    Номер договора {item.number} ₽
                  </p>
                  <p className="text-sm text-[#8a919c]">
                    Дата {format(item.date, 'dd.MM.yyyy')}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {activeOrderGuid && (
        <DialogSimple
          isOpen={!!activeOrderGuid}
          onClose={() => setActiveOrderGuid(null)}
          dialogPanelStyle={{
            maxWidth: 'fit-content',
          }}
        >
          <OrderInfo guid={activeOrderGuid} />
        </DialogSimple>
      )}
    </>
  );
};
