import { axiosInstance } from 'shared/api/axios.defaults';

import type { ChargeSequentially, PaymentParams } from './model';

export class PaymentApi {
  static readonly KEY = 'order';

  static async initPayment(params: PaymentParams): Promise<string> {
    const { data } = await axiosInstance.post(
      `/card/${params.id}/charge`,
      params,
    );
    return data;
  }

  static async chargeSequentially(params: ChargeSequentially): Promise<string> {
    const { data } = await axiosInstance.post(
      `/card/charge-sequentially`,
      params,
    );
    return data;
  }
}
