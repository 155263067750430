import { Card } from 'entities/card/model';

import { axiosInstance } from 'shared/api/axios.defaults';

export class CardApi {
  static readonly KEY = 'card';

  static async getCards(): Promise<Card[]> {
    const { data } = await axiosInstance.get(`/card`);
    return data.sort((a: Card, b: Card) => {
      if (a.isDefault) return -1;
      if (b.isDefault) return 1;
      return 0;
    });
  }

  static async addCard(): Promise<string> {
    const { data } = await axiosInstance.post(`/card`);
    return data;
  }

  static async makeDefaultCard(id: string): Promise<{ success: boolean }> {
    const { data } = await axiosInstance.post(`/card/${id}/make-default`);
    return data;
  }

  static async deleteCard(id: string): Promise<{ success: boolean }> {
    const { data } = await axiosInstance.delete(`/card/${id}`);
    return data;
  }
}
