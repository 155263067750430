import { cx } from '@emotion/css';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup.js';
import * as yup from 'yup';

import { Field, Input } from '@headlessui/react';
import { useMutation } from '@tanstack/react-query';
import { ChangeEvent, ClipboardEvent, useState } from 'react';
import { useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';

import { StepType } from 'pages/recovery-page/ui';
import { Step } from 'pages/recovery-page/ui/steps';

import { AuthApi } from 'shared/api';
import { formatPhoneNumber, onAxiosError } from 'shared/lib';
import type { RecoveryPassword } from 'shared/typings';
import { ButtonBack, ButtonColored } from 'shared/ui';

const schema = yup.object({
  login: yup
    .string()
    .trim()
    .test('min-length', 'Введите минимум 10 символов', (value) => {
      const digits = value ? value.replace(/\D/g, '') : '';
      return digits.length >= 11;
    })
    .required('Номер телефона не заполнен'),
});

export const StepRecovery = ({
  setStep,
}: {
  setStep: (step: StepType) => void;
}) => {
  const [phoneNumber, setPhoneNumber] = useState('');

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  const signInMutation = useMutation({
    mutationFn: (data: RecoveryPassword) => AuthApi.recovery(data),
    onSuccess: (data) => {
      if (data.success) {
        setStep(Step.APPROVAL);
      }
    },
    onError: onAxiosError,
  });

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const rawInput = e.target.value;
    setPhoneNumber(formatPhoneNumber(rawInput));
  };

  const onPaste = (e: ChangeEvent<HTMLInputElement> & ClipboardEvent) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData('Text');
    setPhoneNumber(formatPhoneNumber(pastedText));
  };

  const onSubmit = async (data: RecoveryPassword) => {
    signInMutation.mutate({
      login: `+${data.login.replace(/\D/g, '')}`,
    });
  };

  return (
    <div className="flex size-full">
      <form
        className="m-auto flex w-[600px] flex-col gap-4 self-center bg-white p-12"
        onSubmit={handleSubmit(onSubmit)}
      >
        <p className="flex flex-col text-justify text-xl font-normal md:text-2xl">
          <span className="text-[30px]">Восстановление пароля</span>
        </p>
        <div className="flex flex-col gap-4">
          <Field className="flex w-full flex-col gap-2">
            <InputMask
              mask="+7 (999) 999-99-99"
              {...register('login')}
              value={phoneNumber}
              onChange={onChange}
              onPaste={onPaste}
              placeholder="Введите данные"
              className={cx(
                'flex items-center border border-[#575757] py-1.5 px-3 text-sm/6 text-[#727272] h-[60px] rounded-md w-full',
                'focus:outline-none hover:border-brand-main-hover',
              )}
            >
              {(props: any) => (
                <Input {...props} placeholder="+7 (999) 999-99-99" />
              )}
            </InputMask>
            {errors['login'] && (
              <span className="text-rose-700">{errors['login'].message}</span>
            )}
          </Field>
        </div>
        <div className="flex gap-2">
          <ButtonColored
            text="Отправить смс с кодом"
            disabled={signInMutation.isPending}
          />
          <ButtonBack />
        </div>
      </form>
    </div>
  );
};
