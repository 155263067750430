import { useMutation } from '@tanstack/react-query';

import { DocumentApi } from 'entities/document';

import { onAxiosError } from 'shared/lib';

export const ButtonDownloadPersonalData = ({
  orderGuid,
}: {
  orderGuid: string;
}) => {
  const onDownloadAgreementPurchaseMutation = useMutation({
    mutationFn: () => DocumentApi.agreementPurchase(orderGuid),
    onSuccess: (blob) => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'personal-data.pdf';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);
    },
    onError: onAxiosError,
  });

  return (
    <span>
      <a
        href="/"
        className="cursor-pointer underline hover:text-brand-main-hover"
        onClick={(event) => {
          event.preventDefault();
          onDownloadAgreementPurchaseMutation.mutate();
        }}
      >
        Согласие об обработке персональных данных
      </a>
    </span>
  );
};
