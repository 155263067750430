import { format } from 'date-fns';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { toast } from 'react-toastify';

import { AddCardDialog } from 'features/add-card/ui';

import { CardApi } from 'entities/card';
import { ProfileApi } from 'entities/profile';

import { detectCardType, onAxiosError } from 'shared/lib';
import { Button, ButtonColored, Spinner } from 'shared/ui';

export const ProfileInfo = () => {
  const client = useQueryClient();
  const [isOpen, setIsOpen] = useState(false);

  const { data } = useQuery({
    queryKey: [ProfileApi.KEY, 'getProfile'],
    queryFn: ProfileApi.getProfile,
  });

  const { data: cards } = useQuery({
    queryKey: [CardApi.KEY, 'getCards'],
    queryFn: CardApi.getCards,
  });

  const addCardMutation = useMutation({
    mutationFn: () => CardApi.addCard(),
    onSuccess: (data) => {
      window.open(data);
      client.refetchQueries({ queryKey: [CardApi.KEY, 'getCards'] });
    },
    onError: onAxiosError,
  });

  const deleteCardMutation = useMutation({
    mutationFn: (id: string) => CardApi.deleteCard(id),
    onSuccess: () => {
      toast.success('Карта успешно удалена');
      client.refetchQueries({ queryKey: [CardApi.KEY, 'getCards'] });
    },
    onError: onAxiosError,
  });

  const makeDefaultMutation = useMutation({
    mutationFn: (id: string) => CardApi.makeDefaultCard(id),
    onSuccess: () => {
      client.refetchQueries({ queryKey: [CardApi.KEY, 'getCards'] });
    },
    onError: onAxiosError,
  });

  if (!data || !cards) return <Spinner />;

  return (
    <>
      <div className="w-2/5">
        <div className="flex flex-col justify-center gap-4">
          <p className="text-[18px]">Профиль</p>
          <div className="mt-4 flex flex-col gap-2">
            <div className="grid h-8 grid-cols-[1fr_1fr] items-center border-b">
              <span className="font-second text-sm leading-[120%]">
                Неизрасходованный лимит
              </span>
              <span className="font-second text-right text-[16px] font-bold">
                {data.remainingPaymentLimit} ₽
              </span>
            </div>
            <div className="grid h-8 grid-cols-[1fr_1fr] items-center border-b">
              <span className="font-second text-sm leading-[120%]">ФИО</span>
              <span className="font-second text-right text-[16px] font-bold">
                {data.fullName}
              </span>
            </div>
            <div className="grid h-8 grid-cols-[1fr_1fr] items-center border-b">
              <span className="font-second text-sm leading-[120%]">
                Дата рождения
              </span>
              <span className="font-second text-right text-[16px] font-bold">
                {format(new Date(data.birthDate), 'dd.MM.yyyy г.')}
              </span>
            </div>
            <div className="grid h-8 grid-cols-[1fr_1fr] items-center border-b">
              <span className="font-second text-sm leading-[120%]">
                Номер телефона
              </span>
              <span className="font-second text-right text-[16px] font-bold">
                {data.phoneNumber}
              </span>
            </div>
            <div className="grid h-8 grid-cols-[1fr_1fr] items-center border-b">
              <span className="font-second text-sm leading-[120%]">Email</span>
              <span className="text-right text-[16px] font-bold">
                {data.email}
              </span>
            </div>
          </div>

          <p className="text-[18px]">Карты</p>
          <div className="relative mt-4 flex flex-col gap-2">
            {cards.map((card) => (
              <div
                key={card.pan}
                className="grid h-8 grid-cols-[1fr_1fr_1fr] items-center border-b"
              >
                <span className="flex items-center text-[16px]">
                  {detectCardType(card.pan)}
                  {card.isDefault && (
                    <span className="mx-2 rounded border-2 border-brand-main-color px-2 text-[12px] text-brand-main-color">
                      основная
                    </span>
                  )}
                </span>
                <span />
                <span className="text-right text-[16px] font-bold">
                  {card.pan}
                </span>
                {!card.isDefault && cards.length > 1 && (
                  <Button
                    className="absolute -right-52 h-fit border-0 text-start"
                    onClick={() => deleteCardMutation.mutate(card.cardId)}
                  >
                    <span className="w-fit border-b border-black text-black">
                      Удалить
                    </span>
                  </Button>
                )}
                {!card.isDefault && (
                  <Button
                    className="absolute -right-72 h-fit border-0 text-start"
                    onClick={() => makeDefaultMutation.mutate(card.cardId)}
                  >
                    <span className="w-fit whitespace-nowrap border-b border-black text-black">
                      Установить по умолчанию
                    </span>
                  </Button>
                )}
              </div>
            ))}
            <ButtonColored
              className="mt-4 w-[233px]"
              onClick={() => addCardMutation.mutate()}
            >
              Привязвать новую карту
            </ButtonColored>
          </div>
        </div>
      </div>
      <AddCardDialog isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </>
  );
};
