import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox } from '@headlessui/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { AddCardDialog } from 'features/add-card/ui';
import { ConfirmDialog } from 'features/pay/ui/confirm-dialog';

import { CardApi } from 'entities/card';
import { OrderApi } from 'entities/order';
import { PaymentApi } from 'entities/payment';
import { ProfileApi } from 'entities/profile';

import { onAxiosError } from 'shared/lib';
import {
  Button,
  ButtonColored,
  DialogSimple,
  DialogSimpleProps,
  Spinner,
} from 'shared/ui';

type AddCard = {
  onClick?: () => void;
} & DialogSimpleProps;

export const PayDialog = ({ isOpen, onClose }: AddCard) => {
  const [isOpenNewCard, setIsOpenNewCard] = useState(false);
  const [selectedCardId, setSelectedCardId] = useState('');
  const [paymentSum, setPaymentSum] = useState(0);
  const [paymentRest, setPaymentRest] = useState(0);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);

  const client = useQueryClient();

  const { data } = useQuery({
    queryKey: [ProfileApi.KEY, 'getProfile'],
    queryFn: ProfileApi.getProfile,
  });

  const { data: cards } = useQuery({
    queryKey: [CardApi.KEY, 'getCards'],
    queryFn: CardApi.getCards,
  });

  const { data: orders } = useQuery({
    queryKey: [OrderApi.KEY, 'getOrders', data],
    queryFn: OrderApi.getOrders,
  });

  const { data: order } = useQuery({
    queryKey: [OrderApi.KEY, 'getOrder', data],
    queryFn: () => OrderApi.getOrder(orders![0].guid),
    enabled: !!orders,
  });

  const paymentMutation = useMutation({
    mutationFn: () =>
      PaymentApi.initPayment({
        orderGuid: orders![0].guid,
        sum: paymentSum,
        id: selectedCardId,
      }),
    onSuccess: () => {
      toast.success('Оплата прошла успешно');
      client.refetchQueries({ queryKey: [ProfileApi.KEY, 'getProfile'] });
    },
    onError: onAxiosError,
  });

  const addCardMutation = useMutation({
    mutationFn: () => CardApi.addCard(),
    onSuccess: (data) => {
      window.open(data);
      client.refetchQueries({ queryKey: [ProfileApi.KEY, 'getProfile'] });
    },
    onError: onAxiosError,
  });

  const makeDefaultCardMutation = useMutation({
    mutationFn: (id: string) => CardApi.makeDefaultCard(id),
    onSuccess: () => {
      client.refetchQueries({ queryKey: [CardApi.KEY, 'getCards'] });
    },
    onError: onAxiosError,
  });

  useEffect(() => {
    if (!order) return;

    const rest =
      +order.sum -
      order.payments.reduce(
        (prev, next) => prev + (!next.success ? +next.sum : 0),
        0,
      );
    setPaymentSum(rest);
    setPaymentRest(rest);
  }, [!!order]);

  useEffect(() => {
    if (!cards) return;
    const selectedCardId = cards.find((card) => card.isDefault)!.cardId;
    setSelectedCardId(selectedCardId);
  }, [cards]);

  if (!order || !data || !cards) return <Spinner />;

  const paymentNext = order.payments.find((payment) => !payment.success)?.sum;

  const onClick = (id: string) => {
    if (selectedCardId !== id) setSelectedCardId(id);
  };

  return (
    <>
      <DialogSimple isOpen={isOpen} onClose={onClose}>
        <div className="flex size-full">
          <div className="flex w-full flex-col gap-4 self-center bg-white">
            <p className="flex flex-col text-center text-xl font-normal md:text-2xl">
              <span className="text-[30px]">Способы оплаты</span>
            </p>
            <div className="flex flex-col gap-4">
              <p className="flex flex-col text-xl font-normal md:text-2xl">
                Выберите карту для оплаты
              </p>
              {cards.map((card, index) => (
                <div className="flex items-center gap-1.5">
                  <Button
                    key={index}
                    onClick={() => {
                      onClick(card.cardId);
                    }}
                    className={
                      selectedCardId === card.cardId
                        ? 'bg-brand-main-color text-white hover:text-white'
                        : ''
                    }
                  >
                    <div className="flex-center ">
                      {card.pan}
                      {selectedCardId === card.cardId && (
                        <Checkbox
                          checked={selectedCardId === card.cardId}
                          className="group size-6 fill-white flex-center"
                        >
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="hidden size-4 fill-white text-white group-data-[checked]:block"
                          />
                        </Checkbox>
                      )}
                    </div>
                  </Button>

                  {!card.isDefault && (
                    <p
                      className="cursor-pointer text-brand-main-color underline"
                      onClick={() =>
                        makeDefaultCardMutation.mutate(card.cardId)
                      }
                    >
                      установить по умолчанию
                    </p>
                  )}
                </div>
              ))}
              <hr />
              <Button
                className="relative"
                onClick={() => {
                  addCardMutation.mutate();
                }}
                disabled={addCardMutation.isPending}
              >
                Привязать новую карту
              </Button>
            </div>
            <hr />
            <div className="flex justify-center gap-2">
              <ButtonColored
                className="relative"
                onClick={() => {
                  setPaymentSum(paymentRest);
                }}
              >
                Полное погашение <br />
                {paymentRest} ₽
              </ButtonColored>
              {paymentNext && (
                <ButtonColored
                  onClick={() => {
                    setPaymentSum(paymentNext);
                  }}
                  disabled={paymentMutation.isPending}
                >
                  Следующий платеж <br />
                  {paymentNext} ₽
                </ButtonColored>
              )}
            </div>
            <p className="flex justify-center text-xl font-normal md:text-2xl">
              Сумма к оплате {paymentSum} ₽
            </p>
            <div className="flex justify-center gap-2">
              <ButtonColored
                className="relative"
                onClick={() => setIsOpenConfirm(true)}
                disabled={paymentMutation.isPending}
              >
                Оплатить выбранной картой
              </ButtonColored>
              <Button onClick={onClose}>Отмена</Button>
            </div>
          </div>
        </div>
      </DialogSimple>
      <AddCardDialog
        isOpen={isOpenNewCard}
        onClose={() => setIsOpenNewCard(false)}
      />
      <ConfirmDialog
        isOpen={isOpenConfirm}
        onClose={() => setIsOpenConfirm(false)}
        onContinue={() => {
          paymentMutation.mutate();
          setIsOpenConfirm(false);
        }}
        title="Уверены, что хотите продолжить?"
        paragraph={`подтверждая действие, с вашей карты будут списаны денежные средства в размере ${paymentSum} ₽`}
      />
    </>
  );
};
