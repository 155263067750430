import { cx } from '@emotion/css';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup.js';
import * as yup from 'yup';
import { InferType } from 'yup';

import { Button, Field, Input } from '@headlessui/react';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';

import { CardApi } from 'entities/card';

import { onAxiosError } from 'shared/lib';
import { ButtonColored, DialogSimple, DialogSimpleProps } from 'shared/ui';

type AddCard = {
  onClick?: () => void;
} & DialogSimpleProps;

const schema = yup.object({
  newCard: yup
    .string()
    .trim()
    .min(16, 'Введите 16 символов банковской карты')
    .required('Поле должно быть заполнено'),
});

export const AddCardDialog = ({ isOpen, onClose }: AddCard) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  const addCardMutation = useMutation({
    mutationFn: () => CardApi.addCard(),
    onSuccess: (data) => {
      console.log(data);
      // toast.success('Карта добавлена');
      // onClose();
    },
    onError: onAxiosError,
  });

  const onSubmit = (data: InferType<typeof schema>) => {
    addCardMutation.mutate(data);
  };

  return (
    <DialogSimple
      isOpen={isOpen}
      onClose={onClose}
      dialogPanelStyle={{
        width: 'fit-content',
      }}
    >
      <div className="flex size-fit">
        <div className="flex size-full flex-col justify-between gap-4 self-center bg-white">
          <p className="flex flex-col text-justify text-xl font-normal md:text-2xl">
            <span className="text-[30px]">Добавление новой карты</span>
          </p>
          <div className="flex flex-col gap-4">
            <Field className="flex w-full flex-col gap-2">
              <InputMask
                mask="9999 9999 9999 9999"
                {...register('newCard')}
                placeholder="Номер карты"
                className={cx(
                  'flex items-center border border-[#575757] py-1.5 px-3 text-sm/6 text-[#727272] h-[60px] rounded-md w-full',
                  'focus:outline-none hover:border-brand-main-hover',
                )}
              >
                {(props: any) => (
                  <Input {...props} placeholder="9999 9999 9999 9999" />
                )}
              </InputMask>
              {errors['newCard'] && (
                <span className="text-rose-700">
                  {errors['newCard'].message}
                </span>
              )}
            </Field>
          </div>
          <div className="flex gap-2">
            <ButtonColored
              text="Подтвердить"
              disabled={addCardMutation.isPending}
              onClick={handleSubmit(onSubmit)}
            />
            <Button onClick={onClose}>Отмена</Button>
          </div>
        </div>
      </div>
    </DialogSimple>
  );
};
