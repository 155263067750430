import { axiosInstance } from 'shared/api/axios.defaults';

export class DocumentApi {
  static readonly KEY = 'documents';

  static async debtCertificate(orderGuid: string): Promise<File> {
    const { data } = await axiosInstance.get(
      `/documents/debt-certificate?orderGuid=${orderGuid}`,
      {
        responseType: 'blob',
      },
    );
    return data;
  }

  static async agreementPurchase(orderGuid: string): Promise<File> {
    const { data } = await axiosInstance.get(
      `/documents/agreement-purchase?orderGuid=${orderGuid}`,
      {
        responseType: 'blob',
      },
    );
    return data;
  }

  static async agreementPersonalData(orderGuid: string): Promise<File> {
    const { data } = await axiosInstance.get(
      `/documents/agreement-personal-data?orderGuid=${orderGuid}`,
      {
        responseType: 'blob',
      },
    );
    return data;
  }
}
