import { cx } from '@emotion/css';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup.js';
import { AxiosError } from 'axios';
import * as yup from 'yup';

import { Field, Input } from '@headlessui/react';
import { useMutation } from '@tanstack/react-query';
import { ChangeEvent, ClipboardEvent, useState } from 'react';
import { useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { AuthApi } from 'shared/api';
import { useAuthContext } from 'shared/hooks';
import { formatPhoneNumber } from 'shared/lib';
import { RoutesPath } from 'shared/routes-path';
import type { LoginData } from 'shared/typings';
import { ButtonColored } from 'shared/ui';

// @ts-ignore
const isDevMode = import.meta.env.DEV;

const schema = yup.object({
  username: yup.string().lowercase().trim().optional(),
  password: yup
    .string()
    .trim()
    .min(4, 'Пароль должен содержать минимум 4 символа')
    .required('Пароль обязательное поле'),
});

export const AuthPage = () => {
  const { setIsAuth } = useAuthContext();
  const navigate = useNavigate();

  const [phoneNumber, setPhoneNumber] = useState(
    isDevMode ? '+79031144237' : '',
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  const signInMutation = useMutation({
    mutationFn: (data: LoginData) => AuthApi.login(data),
    onSuccess: (data) => {
      setIsAuth(true);
      navigate(RoutesPath.INSTALLMENT);

      sessionStorage.setItem('accessToken', data.accessToken);
      localStorage.setItem('refreshToken', data.refreshToken);
    },
    onError: (error: AxiosError) => {
      console.error('Sign in error:', error);
      // @ts-ignore
      toast.error(error.response.data.errorMessage);
    },
  });

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const rawInput = e.target.value;
    setPhoneNumber(formatPhoneNumber(rawInput));
  };

  const onPaste = (e: ChangeEvent<HTMLInputElement> & ClipboardEvent) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData('Text');
    setPhoneNumber(formatPhoneNumber(pastedText));
  };

  const onSubmit = (data: LoginData) => {
    signInMutation.mutate({
      ...data,
      username: `+${phoneNumber.replace(/\D/g, '')}`,
    });
  };

  return (
    <div className="flex size-full">
      <form
        className="m-auto flex w-[600px] flex-col gap-4 self-center bg-white p-12"
        onSubmit={handleSubmit(onSubmit)}
      >
        <p className="flex flex-col text-justify text-xl font-normal md:text-2xl">
          <span className="text-[30px]">Вход</span>
          <span className="pt-2 text-[16px] text-[#727272]">
            Введите данные пользователя
          </span>
        </p>
        <div className="flex flex-col gap-4">
          <Field className="flex w-full flex-col gap-2">
            <InputMask
              mask="+7 (999) 999-99-99"
              value={phoneNumber}
              onChange={onChange}
              onPaste={onPaste}
              placeholder="Введите данные"
              className="flex h-[60px] w-full items-center rounded-md border border-[#575757] px-3 py-1.5 text-sm/6 text-[#727272] hover:border-brand-main-hover focus:outline-none"
            >
              {(props: any) => (
                <Input {...props} placeholder="+7 (999) 999-99-99" />
              )}
            </InputMask>
            {errors['username'] && (
              <span className="text-rose-700">
                {errors['username'].message}
              </span>
            )}
          </Field>
          <Field className="flex w-full flex-col gap-2">
            <Input
              {...register('password')}
              type="password"
                // defaultValue={isDevMode ? '7779777Bb?' : ''}
                defaultValue={isDevMode ? 'Asdfasdf1!' : ''}
              placeholder="Введите данные"
              className={cx(
                'flex items-center border border-[#575757] py-1.5 px-3 text-sm/6 text-[#727272] h-[60px] rounded-md w-full',
                'focus:outline-none hover:border-brand-main-hover',
              )}
            />
            {errors['password'] && (
              <span className="text-rose-700">
                {errors['password'].message}
              </span>
            )}
          </Field>
          <p className="text-sm text-[#727272]">
            Забыли пароль?
            <Link
              to={RoutesPath.RECOVERY_PASSWORD}
              className="whitespace-nowrap pl-4 text-brand-main-color underline"
            >
              Восстановить пароль
            </Link>
          </p>
        </div>
        <ButtonColored
          text="Войти"
          className="relative"
          disabled={signInMutation.isPending}
        />
      </form>
    </div>
  );
};
